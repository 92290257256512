import React, { useRef } from "react";

const Banner = () => {
    const bannerRef = useRef(null);

    const closeBanner = () => {
        // Start the height animation
        const bannerElement = bannerRef.current;
        bannerElement.style.height = `${bannerElement.offsetHeight}px`;
        void bannerElement.offsetHeight;
        bannerElement.style.height = '0px';
        bannerElement.style.paddingTop = '0px';
        bannerElement.style.paddingBottom = '0px';
    }

    return (
        <div ref={bannerRef} className="bg-cb-dark-red min-h-11 pt-3 sm:pt-3.5 pb-3 font-normal md:font-medium banner-container">
            <div className="m-auto flex justify-between items-center text-white max-w-7xl px-9">
                <div className="dismissible-banner-text">
                    <a href="https://couchbase.live" className="banner hover:underline">
                        Run some code on Couchbase Playground now, the fastest way to try Couchbase
                    </a>
                </div>
                <button onClick={closeBanner} className="close-btn">X</button>
            </div>
        </div>
    )
}

export default Banner;
    
import React from 'react';
import { navigate } from 'gatsby'
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach'
import { parse, stringify } from 'query-string';
import Layout from './src/components/Layout'

function generatePath(location) {
  return location.pathname + location.search;
}

const history = {
  push: (location) => {
    navigate(generatePath(location), { replace: false, state: location.state });
  },
  replace: (location) => {
    navigate(generatePath(location), { replace: true, state: location.state });
  },
};

export const wrapPageElement = ({ element, props }) => (
  <QueryParamProvider
    history={history}
    location={props.location}
    adapter={ReachAdapter}
    options={{
      searchStringToObject: parse,
      objectToSearchString: stringify,
    }}
  >
    <Layout {...props}>
      {element}
    </Layout>
  </QueryParamProvider>
)

import { useEffect } from 'react'

const useMenuLinks = () => {
  useEffect(() => {
    const menuLinks = document.querySelectorAll('.menu_link')
    let sdkDropdown = null
    menuLinks.forEach(link => {
      link.addEventListener('focus', function() {
        // when tabbed forwards onto SDKs button
        this.parentElement.classList.add('menu-focus')
        sdkDropdown = this.parentElement
      })

      link.addEventListener('focusout', function(event) {
        // when tabbed backwards off SDKs button
        if (event.relatedTarget != null && 
            ((
              event.relatedTarget.textContent === 'documentation' || 
              event.relatedTarget.textContent === 'docs')  || 
              event.relatedTarget.id === 'gatsby-focus-wrapper'
            )) {
          this.parentElement.classList.remove('menu-focus')
        }
      })

      const subMenus = document.querySelectorAll('.submenu')
      subMenus.forEach(submenu => {
        const subMenuLinks = Array.from(
          submenu.querySelectorAll('.submenu_link')
        )
        const lastSubMenuLink = subMenuLinks.pop()
        subMenuLinks.forEach(subMenuLink => {
          subMenuLink.addEventListener('focusout', (event) => {
            // when click outside menu while any item is selected
            if (
              event.relatedTarget != null && 
              event.relatedTarget.id === 'gatsby-focus-wrapper'
            ) {
              sdkDropdown.classList.remove('menu-focus')
            }
          })
        })
        lastSubMenuLink.addEventListener('focusout', (event) => {
          // when tabbed beyond the last item
          if (
            event.relatedTarget != null && 
            event.relatedTarget.textContent === 'tutorials'
          ) {
            submenu.parentElement.classList.remove('menu-focus')
          }
        })
        lastSubMenuLink.addEventListener('focus', () => {
          // if tabbed backwards onto last item
          submenu.parentElement.classList.add('menu-focus')
        })
      })

    })
  })
}

export default useMenuLinks
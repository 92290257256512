import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import '../styles/modules/footer.scss'

import facebook from '../assets/social-icons/facebook_black.svg'
import stackOverFlow from '../assets/social-icons/stackoverflow_black.svg'
import github from '../assets/social-icons/github_black.svg'
import youtube from '../assets/social-icons/footer_icons_youtube.svg'
import discord from '../assets/social-icons/footer_icons_discord.svg'
import twitter from '../assets/social-icons/twitter_black.svg'
import linkedIn from '../assets/social-icons/linkedin_black.svg'

const Footer = () => {
  const context = useContext(AppContext)
  const isLight = context.theme === 'light'
  return (
    <footer className={`z-10 relative pt-7 ${isLight ? 'bg-gray-230 text-black-light-thin' : 'bg-gray-900 text-ghost-white'} `}>
      <div className="footer-links footer flex flex-wrap pt-8 pb-2 font-gothamBook">
        <div className="w-full sm:pl-2 lg:w-1/5 md:pl-0 mb-10">
          <div className="footer-logo inline-block mb-3">
            <a href="https://www.couchbase.com" className="hover:no-underline inline-block">
              <svg width="192" height="53" data-name="Layer 1" className="h-12" viewBox="0 0 517.8 119.7">
                <title>Couchbase home page link</title>
                <path id="Logo-Icon" className={`fill-current ${isLight ? 'text-red-450' : 'text-white'}`} d="M98.5,70.2a7.21,7.21,0,0,1-6.1,7.4c-6.9,1.2-21.5,2-33.7,2S32,78.9,25,77.6a7.12,7.12,0,0,1-6-7.4V47.1a7.71,7.71,0,0,1,6-7.4A72.67,72.67,0,0,1,35.6,39a2.69,2.69,0,0,1,2.6,2.8V57.9c7.2,0,13.4-.4,20.6-.4s13.4.4,20.6.4V41.8A2.56,2.56,0,0,1,81.9,39a73.73,73.73,0,0,1,10.6.7,7.8,7.8,0,0,1,6.1,7.4V70.2ZM58.7.7C26.3.7,0,27.2,0,59.9A58.87,58.87,0,0,0,58.7,119c32.4,0,58.7-26.5,58.7-59.1S91.2.7,58.7.7Z" />
                <g fill={`${isLight ? '#000000' : '#FFFFFF'}`}>
                  <path d="M179.72,88.23c-18.2,0-27.4-13.3-27.4-28s9.5-27.8,27.6-27.8c6.9,0,11.9,1.5,16,4.3l-5.2,8.6a18.88,18.88,0,0,0-11-3.1c-9.8,0-15,7.9-15,17.7,0,10.1,5,18.5,15.2,18.5a18.62,18.62,0,0,0,12-4.1l4.8,8.3c-2.8,2.4-9.3,5.6-17,5.6" />
                  <path d="M218.62,88.23c-13.6,0-19.6-10.4-19.6-20.6s5.9-20.8,19.5-20.8,19.8,10.3,19.8,20.5c0,10-6,20.9-19.7,20.9m-.3-33.2c-5.9,0-7.7,5-7.7,12.2s2.3,12.5,8.1,12.5,7.9-4.9,7.9-12.2-2.3-12.5-8.3-12.5" />
                  <path d="M255.62,47.73V73c0,3.9,1.3,5.8,5.1,5.8s6.9-3.8,7.7-4.8V47.73h11v27.9a57.69,57.69,0,0,0,1.1,11.6h-10.8a33.61,33.61,0,0,1-.8-5.2,15.53,15.53,0,0,1-12.5,6.2c-8.6,0-11.7-5.6-11.7-12.8V47.73Z" />
                  <path d="M306.32,88.23c-12.6,0-20.3-8.3-20.3-20.7,0-13.3,8.7-20.8,20.7-20.8,6.3,0,10.1,1.9,11.9,3.1l-3.5,7.6a14.2,14.2,0,0,0-8.1-2.3c-6.1,0-9.4,4.8-9.4,12s3.1,12.4,9.6,12.4a15.47,15.47,0,0,0,8.3-2.3l3.3,7.4c-1.8,1.2-5.4,3.6-12.5,3.6" />
                  <path d="M348.32,87.23V61.93c0-3.9-1.3-5.8-5-5.8-4,0-7,3.7-7.8,4.8v26.4h-11V28.93h11v23.5a15.74,15.74,0,0,1,12.1-5.7c8.6,0,11.7,5.5,11.7,12.8v27.6h-11Z" />
                  <path d="M379.22,28.93v21.9a13.83,13.83,0,0,1,9.5-4.1c9.7,0,16.5,6.7,16.5,20.4s-9,21-20.8,21c-8.9,0-14.3-2.2-16.2-3.2v-56Zm0,49.7a16.44,16.44,0,0,0,5.2.8c5.7,0,9.1-4.2,9.1-12.3,0-7.3-2.4-11.6-8-11.6a9.07,9.07,0,0,0-6.4,2.9v20.2Z" />
                  <path d="M432.52,87.23a26.28,26.28,0,0,1-.7-4.2,14.12,14.12,0,0,1-10.9,5.2c-6.6,0-11.8-4-11.8-11.1,0-10.3,10.4-14,20.8-14h1.3v-2.3c0-3.3-1.3-5.3-6.2-5.3a16.27,16.27,0,0,0-9.9,3.7l-4.7-6.8a23.45,23.45,0,0,1,15.9-5.7c10.8,0,15.9,4.2,15.9,14.7v13.9a61.29,61.29,0,0,0,1.1,11.9Zm-1.2-17.4H430c-6,0-10.2,1.8-10.2,6.3,0,2.8,2.2,4,4.6,4a9,9,0,0,0,6.9-3.3Z" />
                  <path d="M462,88.23c-7,0-11.5-2.1-13.9-3.7l3.7-7.8a18.42,18.42,0,0,0,9.6,3.2c4,0,6.3-1.1,6.3-3.4,0-2.6-4.4-3.3-9.9-6s-8.5-5.7-8.5-11.6c0-7.5,5.8-12.2,14.7-12.2a24.88,24.88,0,0,1,12.6,3.1l-3.8,7.6a15.66,15.66,0,0,0-8.3-2.5c-3.7,0-5.3,1.3-5.3,3.3,0,2.6,3.6,3.2,8,5,6.1,2.5,10.5,5.4,10.5,12.1-.1,8.5-6,12.9-15.7,12.9" />
                  <path d="M503.12,79.93a19.2,19.2,0,0,0,10.1-2.7l3.5,7.1c-2.6,1.5-6.6,3.8-14.6,3.8-13.1,0-20.5-8.3-20.5-20.9s8.6-20.5,19.4-20.5c12.3,0,18.2,8.6,16.9,23.4h-24.5c.4,5.9,3.4,9.8,9.7,9.8Zm4.1-17.3c-.1-4.4-1.8-7.9-6.5-7.9-4.4,0-6.8,2.8-7.5,7.9Z" />
                </g>
              </svg>
            </a>
          </div>
          <div>
            <p className='text-sm leading-6 text-black-light-thin'>
            3250 Olcott Street<br/>
            Santa Clara, CA 95054<br/>
            United States
            </p>
          </div>
        </div>

        <div className="w-1/2 pl-2 md:w-1/4 md:mb-4 lg:w-1/5 lg:pl-8">
          <ul className='pr-5'>
            <li className='text-red-450 text-sm font-bold'>
              company
            </li>
            <li>
              <a href="https://www.couchbase.com/about">
                about
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/leadership">
                leadership
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/news-and-press-releases">
                news & press
              </a>
            </li>
            <li>
              <a href="https://investors.couchbase.com">
                investor relations
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/careers">
                careers
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/resources/webcasts-and-events">
                events
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/legal">
                legal
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/contact">
                contact us
              </a>
            </li>
          </ul>
        </div>
        <div className="w-1/2  sm:pl-2 md:w-1/4 lg:w-1/5 md:pl-8 mb-4">
          <ul className='pr-5'>
            <li className='text-red-450 text-sm font-bold'>
              support
            </li>
            <li>
              <a href="https://www.couchbase.com/developers">
                Developer portal
              </a>
            </li>
            <li>
              <a href="https://docs.couchbase.com/">
                Documentation
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/forums/">
                Forums
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/professional-services" >
                PROFESSIONAL SERVICES
              </a>
            </li>
            <li>
              <a href="https://support.couchbase.com">
                support login
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/support-policy">
                support policy
              </a>
            </li>
            <li>
              <a href="https://learn.couchbase.com/store">
                training
              </a>
            </li>
          </ul>
        </div>

        <div className="w-1/2 sm:pl-2 md:pl-10 md:w-1/4 lg:w-1/5 md:pl-8 mb-4">
          <ul className='pr-5'>
          <li className='text-red-450 text-sm font-bold'>
             quicklinks
            </li>
            <li>
              <a href="https://www.couchbase.com/blog">
                blog
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/downloads/">
                downloads
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/products/capella/get-started">
                get started
              </a>
            </li>          
            <li>
              <a href="https://www.couchbase.com/resources/" >
                resources
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/resources/why-nosql/">
                why nosql
              </a>
            </li>
            <li>
              <a href="https://www.couchbase.com/pricing">
                pricing
              </a>
            </li>
          </ul>
        </div>

        <div className="w-1/2 sm:pl-2 md:w-1/4 lg:w-1/5 mb-4">
          <ul className="social-icons lg:text-right flex-wrap pr-5">
            <li className='text-red-450 text-sm font-bold w-1/2 md:w-full whitespace-nowrap'>
             follow us
            </li>
            <li>
              <img alt="Social Media Link for Facebook" className="social-icon" width="15px" height="15px" src={facebook} />
              <a href="https://www.facebook.com/Couchbase/">
                Facebook
              </a>
            </li>
            <li>
              <img alt="Social Media Link for Twitter" className="social-icon" width="15px" height="15px" src={twitter} />
              <a href="https://twitter.com/couchbase">
                Twitter
              </a>
            </li>
            <li>
              <img alt="Social Media Link for LinkedIn" className="social-icon" width="15px" height="15px" src={linkedIn} />
              <a href="https://www.linkedin.com/company/couchbase">
                LinkedIn
              </a>
            </li>
            <li>
              <img alt="Social Media Link for Youtube" className="social-icon" width="15px" height="15px" src={youtube} />
              <a href="https://www.youtube.com/c/CouchbaseServer/">
                youtube
              </a>
            </li>
            <li>
              <img alt="Social Media Link for GitHub" className="social-icon" width="15px" height="15px" src={github} />
              <a href="https://github.com/couchbase">
                Github
              </a>
            </li>
            <li className="flex-nowrap text-left">
              <img alt="Social Media Link for Stack Overflow" className="social-icon" width="15px" height="15px" src={stackOverFlow} />
              <a href="https://stackoverflow.com/tags/couchbase/info">
                Stack Overflow
              </a>
            </li>
            <li>
              <img alt="Social Media Link for Discord" className="social-icon" width="15px" height="15px" src={discord} />
              <a href="https://discord.com/invite/K7NPMPGrPk?utm_source=web&utm_medium=footer&utm_campaign=discord">
                discord
              </a>
            </li>                   
          </ul>
        </div>

      </div>

      <div className="footer-terms text-center text-xs border-t border-gray-400 pt-6 pb-3 footer-terms">
        <div>
          <p className="text-base font-light leading-6">
            © {new Date().getFullYear()} Couchbase, Inc. Couchbase, Couchbase Lite and the Couchbase logo are
            registered trademarks of Couchbase,&nbsp;Inc.
          </p>
        </div>
        <a className={`text-sm leading-6 text-black-light-thin inline-block my-space-2 mx-space-1 lg:mr-4 link ${isLight ? 'text-black-light-thin' : 'text-ghost-white'} hover:no-underline font-semibold`} href="https://www.couchbase.com/terms-of-use">Terms of Use</a>
        <a className={`text-sm leading-6 text-black-light-thin inline-block my-space-2 mx-space-1 lg:mr-4 link ${isLight ? 'text-black-light-thin' : 'text-ghost-white'} hover:no-underline font-semibold`} href="https://www.couchbase.com/privacy-policy">Privacy Policy</a>
        <a className={`text-sm leading-6 text-black-light-thin inline-block my-space-2 mx-space-1 lg:mr-4 link ${isLight ? 'text-black-light-thin' : 'text-ghost-white'} hover:no-underline font-semibold`} href="https://www.couchbase.com/cookie-policy">Cookie Policy</a>
        <a className={`text-sm leading-6 text-black-light-thin inline-block my-space-2 mx-space-1 lg:mr-4 link ${isLight ? 'text-black-light-thin' : 'text-ghost-white'} hover:no-underline font-semibold`} href="https://www.couchbase.com/support-policy">Support Policy</a>
        <a className={`text-sm leading-6 text-black-light-thin inline-block my-space-2 mx-space-1 lg:mr-4 link ${isLight ? 'text-black-light-thin' : 'text-ghost-white'} hover:no-underline font-semibold`} href="https://privacyportal.onetrust.com/webform/afc19fb6-5052-4604-b903-873902b65da9/f38c3069-1e90-4fda-a820-189d2de605a9">Do Not Sell My Personal Information</a>
        <a className={`text-sm leading-6 text-black-light-thin inline-block my-space-2 mx-space-1 lg:mr-4 link ${isLight ? 'text-black-light-thin' : 'text-ghost-white'} hover:no-underline font-semibold`} href="https://info.couchbase.com/unsubscribe-or-manage-preferences.html">Marketing Preference Center</a>
      </div>

    </footer>
  )
}

export default Footer
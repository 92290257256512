const useEnvironments = () => {
  const activeEnv = process.env.CONTEXT || process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
  const dev = activeEnv === 'development'

  const isBrowser = typeof window !== "undefined"

  const stage = isBrowser ? window.location.hostname.indexOf('staging') > -1 : false;

  return { dev, stage }
}

export default useEnvironments

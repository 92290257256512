import React, { useState, createContext } from 'react'

const AppContext = createContext()

const AppProvider = props => {
  const [appData, setApp] = useState({

    navOpen: false,
    toggleNav: value => setApp(data => (
      { ...data, navOpen: value }
    )),

    layoutFirstChange: false,
    toggleLayoutFirstChange: value => setApp(data => (
      { ...data, layoutFirstChange: value }
    )),

    theme: 'light',
    changeTheme: mode => setApp(data => (
      {...data, theme: mode }
    ))
    
  })
  
  return <AppContext.Provider value={appData}>
    {props.children}
  </AppContext.Provider>
}

export { AppContext, AppProvider }

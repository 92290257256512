import React, { useEffect, useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useMediaPredicate } from 'react-media-hook'

import Header from './Header'
import Footer from './Footer'
import useEnvironments from './hooks/useEnvironments'

import metaImage from '../assets/images/meta/Dev-portal-meta-image.png'
import { ParallaxProvider } from 'react-scroll-parallax'

const Layout = ({ path, children }) => {
  const { dev } = useEnvironments()
  const data = useStaticQuery(graphql`{ site { siteMetadata { title, description, keywords, siteUrl }} }`)
  const { title, description, keywords, siteUrl } = data.site.siteMetadata
  const context = useContext(AppContext)
  const isLight = context.theme === 'light'
  const metaImageUrl = `${siteUrl}${metaImage}`

  const isLarge = useMediaPredicate('(min-width: 1024px)')
  var breakpoint = isLarge ? 'browser-large' : 'browser-small'

  useEffect(() => {
    context.toggleLayoutFirstChange(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`app-container font-hcoGotham ${breakpoint} 
      ${isLight ? 'theme-light' : 'theme-dark'}
      ${context.navOpen ? 'navOpen' : 'navClosed'} 
      ${!context.layoutFirstChange ? 'noChange' : 'changed'}`
    }>
      <ParallaxProvider>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          {/* Favicon Tags */}
          <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
          <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
          <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
          <link rel='manifest' href='/site.webmanifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#ea2328' />
          <meta name='msapplication-TileColor' content='#ea2328' />
          <meta name='theme-color' content='#ffffff' />

          {/* Primary Meta Tags */}
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={metaImageUrl} />

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={siteUrl} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={metaImageUrl} />

          {!dev && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="748511ff-10bf-44bf-88b8-36382e5b5fd9"></script>}
          {!dev && <link type="text/css" rel="stylesheet" href="https://cdn.cookielaw.org/skins/4.3.3/default_flat_bottom_two_button_black/v2/css/optanon.css" />}
          {!dev && <style>{`#optanon ul#optanon-menu li { background-color: #F9F9F9 !important; }#optanon ul#optanon-menu li.menu-item-selected { background-color: #FFFFFF !important; }#optanon #optanon-popup-wrapper .optanon-white-button-middle { background-color: #EA2328 !important; } .optanon-alert-box-wrapper .optanon-alert-box-button-middle { background-color: #EA2328 !important; border-color: #EA2328 !important; } #optanon #optanon-popup-wrapper .optanon-white-button-middle button { color: #ffffff !important; } .optanon-alert-box-wrapper .optanon-alert-box-button-middle button { color: #ffffff !important; } #optanon #optanon-popup-bottom { background-color: #000000 !important; } #optanon.modern #optanon-popup-top, #optanon.modern #optanon-popup-body-left-shading { background-color: #000000 !important; } .optanon-alert-box-wrapper { background-color:#000000 !important; } .optanon-alert-box-wrapper .optanon-alert-box-bg p { color: #FFFFFF !important; } #optanon #optanon-popup-body-right h2 { color: white; } #optanon #optanon-popup-bottom-logo { display: none; }#alert-box-message > a { margin-left: 3px; color: #EA2328; } .onetrust-pc-dark-filter { display: none !important; }`}</style>}
          {!dev && <script>{`function OptanonWrapper(){}`}</script>}
          {!dev && <script>{`function bindCallToActionButtons(){let a=document.getElementById("signInButton"),b=document.getElementById("tryFreeButton");a&&a.addEventListener("click",()=>{dataLayer&&dataLayer.push({event:"customEvent",category:"Navigation Header",action:"https://cloud.couchbase.com/sign-up (from "+window.location.href+")",label:"sign in"})}),b&&b.addEventListener("click",()=>{dataLayer&&dataLayer.push({event:"customEvent",category:"Navigation Header",action:"https://www.couchbase.com/downloads (from "+window.location.href+")",label:"try free"})})}window.addEventListener("load",bindCallToActionButtons);`}</script>}
          {!dev && <script async src="https://www.googleoptimize.com/optimize.js?id=OPT-5F72M7W"></script>}
        </Helmet>
        <Header />
        {children}      
        <Footer />
      </ParallaxProvider>
    </div>
  )
}

export default Layout

import React from 'react'
import { AppProvider } from './src/context/AppContext'

import "./src/styles/tailwind.scss"
import "./src/styles/global.scss"
import "./src/styles/prism-theme-vs-code-dark.scss"
import { Auth0Provider } from '@auth0/auth0-react'

export { wrapPageElement } from './wrapPageElement'

export const wrapRootElement = ({ element, props }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENTID}
    redirectUri={process.env.GATSBY_AUTH0_CALLBACK}
    audience={process.env.GATSBY_AUTH0_AUDIENCE}
    useRefreshTokens={true}
    cacheLocation='localstorage'
  >
    <AppProvider>
      {element}
    </AppProvider>
  </Auth0Provider>
)

import React, { useState, useEffect } from 'react'
import useMenuLinks from '../hooks/useMenuLinks'
import useWindowSize from '../hooks/useWindowSize'

import '../../styles/modules/menu.scss'
import useEnvironments from '../hooks/useEnvironments'
import Hamburger from '../Hamburger'

const buildUrl = (url, stage) => {
    if (stage) {
        return url.slice(0, 8) + 'staging.' + url.slice(8);
    }
    return url;
}

const NavigationBar = () => {

    const { width } =  useWindowSize();
    const [isMobileMenuOpen, setMobileMenu] = useState(false);
    const [isViewportMobile, setIsViewportMobile] = useState(false);

    const handleClick = () => {
        //to close the the expanded tabs, if they are open
        toggleIntegrationMenu(false)
        toggleDeveloperRoleMenu(false)
        toggleArchitectureMenu(false)
        setMobileMenu(!isMobileMenuOpen)
    }

    useEffect(() => {
        stopBackgroundScroll(isMobileMenuOpen)
    }, [isMobileMenuOpen]);

    //To stop the background scroll, when header is expanded 
    const stopBackgroundScroll = (bool) => {
        if (bool === true) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
    }

    const [isIntegrationMenuOpen, toggleIntegrationMenu] = useState(false);
    const [isDeveloperRoleMenuOpen, toggleDeveloperRoleMenu] = useState(false);
    const [isArchitectureMenuOpen, toggleArchitectureMenu] = useState(false);

    useMenuLinks()
    const { stage } = useEnvironments()

    const [isStage, setIsStage] = useState()

    useEffect(() => {
        setIsStage(stage)
    }, [stage])

    useEffect(() => {
        if (width < 1200) {
            setIsViewportMobile(true)
            if (isMobileMenuOpen) {
                stopBackgroundScroll(true)
            }
        } else {
            setIsViewportMobile(false)
            stopBackgroundScroll(false)
        }
    }, [width, isMobileMenuOpen])

    const {
        docsUrl,
        sdkUrl, mobileSdkUrl,
        backendUrl, fullstackUrl, mobileUrl, opsUrl,
        dataModelingUrl, scalabilityUrl, tutorialsUrl, communityUrl,
        signInUrl, tryFreeUrl, developerUrl, couchbaseUrl
    } = {
        couchbaseUrl: "https://www.couchbase.com/",
        developerUrl: "https://www.couchbase.com/developers/",
        docsUrl: `https://docs.couchbase.com/home/index.html`,

        // integrations dropdown
        sdkUrl: `https://www.couchbase.com/developers/sdks/`,
        mobileSdkUrl: `https://www.couchbase.com/developers/mobile-sdks/`,

        // programmers dropdown
        backendUrl: `https://www.couchbase.com/developers/backend/`,
        fullstackUrl: `https://www.couchbase.com/developers/full-stack/`,
        mobileUrl: `https://www.couchbase.com/developers/mobile/`,
        opsUrl: `https://www.couchbase.com/developers/devops-dbas/`,

        // architecture dropdown
        dataModelingUrl: `https://www.couchbase.com/developers/data-modeling/`,
        scalabilityUrl: `https://www.couchbase.com/developers/architecture/`,

        tutorialsUrl: `/tutorials/`,
        communityUrl: `https://www.couchbase.com/developers/community/`,
        
        signInUrl: 'https://cloud.couchbase.com/sign-in/',
        tryFreeUrl: 'https://www.couchbase.com/downloads/',
    }

    return (
        <div className={`py-[23px] flex bg-black xl:h-auto z-50 ${isMobileMenuOpen && isViewportMobile ? 'h-screen overflow-y-auto absolute w-full' : ''}`}>
            <div className='header'>
                <a href={couchbaseUrl} className='py-2 pr-2 pl-3 md:pl-3.5 xl:pl-[15px] sm:p-2.5 rounded-r bg-[#F7F7F7] flex absolute left-0 items-center'>
                    <img className="mr-2.5" alt="Back to the Couchbase homepage" src="https://www.couchbase.com/developers/wp-content/themes/couchbase-developers/images/logo-arrow-icon.png" />
                    <img className="logo-icon ml-px" alt="Couchbase logo" src="https://www.couchbase.com/developers/wp-content/themes/couchbase-developers/images/logo-Icon.png" />
                </a>
                <div className='container'>
                    <div className='logo'>
                        <a href={developerUrl} className="logo-text">Couchbase Developer</a>
                        <div className="hamburger-icon">
                            <Hamburger onClick={handleClick} />
                        </div>
                    </div>
                    <nav className={`menu-container ${isMobileMenuOpen && isViewportMobile ? '' : 'hidden'}`}>
                        <ul className={`main-menu ${isMobileMenuOpen && isViewportMobile ? 'mb-10':''}`}>
                            <hr className='hidden my-4 w-full' />
                            <li className='menu-item'>
                                <a href={docsUrl} className=''>Docs</a>
                            </li>
                            <hr className='hidden my-4 w-full' />
                            <li className='menu-item group relative'>
                                <button className='flex w-full xl:w-fit justify-between' onClick={() => {
                                    toggleDeveloperRoleMenu(false)
                                    toggleArchitectureMenu(false)
                                    toggleIntegrationMenu(!isIntegrationMenuOpen)
                                    }}>
                                    <span className="pr-1">Integrations</span>
                                    <span>
                                        <svg className={`fill-current h-4 w-4 transform xl:group-hover:-rotate-180 transition duration-150 ease-in-out mt-1 ${isIntegrationMenuOpen ? '-rotate-180' : ''}`}
                                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </span>
                                </button>
                                <ul className={`menu-list-items ${isIntegrationMenuOpen ? 'block' : 'hidden min-w-36'}`}>
                                    <li className='py-2.5'><a className="block w-full" href={buildUrl(sdkUrl, isStage)}>SDKs</a></li>
                                    <li className='pt-4'><a className="block w-full" href={buildUrl(mobileSdkUrl, isStage)}>Mobile SDKs</a></li>
                                </ul>
                            </li>
                            <hr className='hidden my-4 w-full' />
                            <li className='menu-item group relative'>
                                <button className='flex w-full xl:w-fit justify-between' onClick={() => {
                                    toggleIntegrationMenu(false)
                                    toggleArchitectureMenu(false)
                                    toggleDeveloperRoleMenu(!isDeveloperRoleMenuOpen)
                                    }}>
                                    <span className="pr-1">Developer Role</span>
                                    <span>
                                        <svg className={`fill-current h-4 w-4 transform xl:group-hover:-rotate-180 transition duration-150 ease-in-out mt-1 ${isDeveloperRoleMenuOpen ? '-rotate-180' : ''}`}
                                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </span>
                                </button>
                                <ul className={`menu-list-items ${isDeveloperRoleMenuOpen ? "block" : "hidden"}`}>
                                    <li className='pt-2.5'><a className="block w-full" href={buildUrl(backendUrl, isStage)}>Backend</a></li>
                                    <li className='pt-4'><a className="block w-full" href={buildUrl(fullstackUrl, isStage)}>Full-stack</a></li>
                                    <li className='pt-4'><a className="block w-full" href={buildUrl(mobileUrl, isStage)}>Mobile</a></li>
                                    <li className='pt-4'><a className="block w-full" href={buildUrl(opsUrl, isStage)}>Ops / DBA</a></li>
                                </ul>
                            </li>
                            <hr className='hidden my-4 w-full' />
                            <li className='menu-item group relative'>
                                <button className='flex w-full xl:w-fit justify-between' onClick={() => {
                                    toggleIntegrationMenu(false)
                                    toggleDeveloperRoleMenu(false)
                                    toggleArchitectureMenu(!isArchitectureMenuOpen)
                                    }}>
                                    <span className="pr-1">Architecture</span>
                                    <span>
                                        <svg className={`fill-current h-4 w-4 transform xl:group-hover:-rotate-180 transition duration-150 ease-in-out mt-1 ${isDeveloperRoleMenuOpen ? '-rotate-180' : ''}`}
                                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </span>
                                </button>
                                <ul className={`menu-list-items ${isArchitectureMenuOpen ? "block" : "hidden"}`}>
                                    <li className='pt-2.5'><a className="block w-full" href={buildUrl(dataModelingUrl, isStage)}>Data Modeling</a></li>
                                    <li className='pt-4'><a className="block w-full" href={buildUrl(scalabilityUrl, isStage)}>Scalability</a></li>
                                </ul>
                            </li>
                            <hr className='hidden my-4 w-full' />
                            <li className='menu-item'>
                                <a href={tutorialsUrl}>Tutorials</a>
                            </li>
                            <hr className='hidden my-4 w-full' />
                            <li className='menu-item'>
                                <a href={buildUrl(communityUrl, isStage)}>Community</a>
                            </li>
                            <li className='signin-button'>
                                <a id="signInButton" href={signInUrl}>
                                    Sign In
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="square"
                                        className="feather feather-log-in cloud inline-block align-bottom pl-1">
                                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                                        <polyline points="10 17 15 12 10 7"></polyline>
                                        <line x1="15" y1="12" x2="3" y2="12"></line>
                                    </svg>
                                </a>
                            </li>
                            <li className='try-free-button'>
                                <a id="tryFreeButton" href={tryFreeUrl} className='cb-orange-button rounded font-semibold !text-black hover:!text-white hover:!opacity-100'>Try Free</a>
                            </li>
                            <hr className='hidden my-4 w-full' />
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default NavigationBar;
